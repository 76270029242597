import * as React from 'react';
import { useFela, } from 'react-fela';

import Image from '../Image/Image';
import Picture from '../Image/Picture';

import getImageAssets from '../../utils/getImageAssets';
import getPictureAssets from '../../utils/getPictureAssets';

// TODO: type with flow

const itemWrapperStyles = ({ theme, }) => ({
  gridArea: '1/1',
  display: 'grid',
  extend: [
    theme.mq({ until: 'l', }, {
      gridTemplateAreas: `
"title"
"."`,
      gridTemplateRows: 'auto 1fr',
    }),
    theme.mq({ from: 'l', }, {
      gridTemplateAreas: `
". ."
"title progress"`,
      gridTemplateRows: '1fr auto',
      gridTemplateColumns: 'auto 1fr',
    }),
  ],
});

const progressWrapperStyles = ({ theme, }) => ({
  gridArea: 'progress',
  zIndex: 1,
  gridAutoFlow: 'column',
  justifySelf: 'end',
  alignSelf: 'end',
  gridGap: '1rem',
  padding: '3rem',
  extend: [
    theme.mq({ until: 'l', }, { display: 'none', }),
    theme.mq({ from: 'l', }, { display: 'grid', }),
    theme.mq({ until: 'xl', }, { gridGap: 'calc(2rem - 2px)', }),
    theme.mq({ from: 'xl', }, { gridGap: 'calc(1rem + 3px)', }),
  ],
});

const getBaseImageProps = ({ image, isPicture, imgOptions, theme, }) => (isPicture
  ? getPictureAssets(imgOptions(theme, image))
  : {
    image,
    imgOptions: getImageAssets(imgOptions(theme)),
  });

function ImageGalleryTeaser({ images = [], imgOptions, isPicture, }) {
  const { theme, css, } = useFela();
  const imageCount = (images || []).length;
  const [ currentImageIndex, setCurrentImageIndex, ] = React.useState(0);
  const ImageComponent = isPicture ? Picture : Image;

  React.useEffect(() => {
    const timeout = setTimeout(() => {
      setCurrentImageIndex(currentImageIndex === imageCount - 1 ? 0 : currentImageIndex + 1);
    }, 5000);

    return () => {
      clearTimeout(timeout);
    };
  }, [ currentImageIndex, imageCount, ]);

  if (!Array.isArray(images) || !images.length) return null;

  const renderedImages = [
    currentImageIndex > 0 ? images[currentImageIndex - 1] : images[imageCount - 1],
    images[currentImageIndex],
    currentImageIndex === imageCount - 1 ? images[0] : images[currentImageIndex + 1],
  ];

  return (
    <div className={css({ display: 'grid', gridTemplateAreas: '"progress"', })}>
      {renderedImages.map((image, renderedImageIndex) => (
        <div
          key={image.contentId}
          className={css(itemWrapperStyles)}
        >
          <ImageComponent
            {...getBaseImageProps({ image, isPicture, imgOptions, theme, })}
            miscStyles={{
              gridArea: [ { until: 'l', value: '1/1/3/2', }, { from: 'l', value: '1/1/3/3', }, ],
              pointerEvents: renderedImageIndex === 1 ? 'auto' : 'none',
              opacity: renderedImageIndex === 1 ? 1 : 0,
              transitionDuration: '0.9s',
              transitionProperty: 'opacity',
            }}
          />
          {image.caption && <ImageTitle title={image.caption} isActive={renderedImageIndex === 1} />}
          {/* This is a placeholder for the "real" progress element */}
          <div className={css(progressWrapperStyles)}>
            {imageCount < 6 ? Array(imageCount).fill().map((_, index) => (
              <div
                // eslint-disable-next-line react/no-array-index-key
                key={index}
                className={css({
                  width: '5px',
                  height: '5px',
                })}
              />
            )) : null}
          </div>
        </div>
      ))}
      <div className={css(progressWrapperStyles)}>
        {imageCount < 6 ? Array(imageCount).fill().map((_, index) => (
          <div
            // eslint-disable-next-line react/no-array-index-key
            key={index}
            className={css({
              width: '5px',
              height: '5px',
              borderRadius: '50%',
              transform: `scale(${index === currentImageIndex ? 1.6 : 1})`,
              backgroundColor: theme.color('neutral', index === currentImageIndex ? '-10' : '-4'),
              transitionDuration: '0.35s',
              boxShadow: '0 0 5px 1px rgba(0, 0, 0, 0.35)',
            })}
          />
        )) : null}
      </div>
    </div>
  );
}

const maxCharacters = (maxCharCount, str) => {
  if (typeof str !== 'string') return false;
  return str.trim().length <= maxCharCount;
};

function ImageTitle({ title, isActive, }) {
  const { css, theme, } = useFela();

  if (!title) return null;

  return maxCharacters(43, title) ? (
    <div
      className={css({
        gridArea: 'title',
        zIndex: 1,
        color: theme.color('white'),
        fontWeight: 700,
        padding: '2rem',
        textShadow: '0px 0px 10px rgba(0,0,0,.25), 1px 1px 1px rgba(0,0,0,.25), -1px -1px 1px rgba(0,0,0,.25)',
        opacity: isActive ? 1 : 0,
        transitionDuration: isActive ? '0.35s' : '0.6s',
        transitionProperty: 'opacity',
        transitionDelay: isActive ? '0.35s' : null,
        extend: [
          theme.type(-1, { until: 's', }),
          theme.type(-1, { from: 'xl', }),
        ],
      })}
    >
      {title}
    </div>
  ) : null;
}

export default ImageGalleryTeaser;
